// @ts-ignore
import { object, number, string, array, boolean, record } from 'valibot';
import { ImageSchema } from '../generalSchemas';

const answersSchema = object({
  id: number(),
  text: string(),
});

const sizesSchema = object({
  l: ImageSchema,
  l_x2: ImageSchema,
  m: ImageSchema,
  m_x2: ImageSchema,
  s: ImageSchema,
  s_x2: ImageSchema,
  xl: ImageSchema,
  xl_x2: ImageSchema,
  xxl: ImageSchema,
  xxl_x2: ImageSchema,
});

// * API schemas
export const getQuickPollSchema = object({
  id: number(),
  data: object({
    id: number(),
    name: string(),
    question: string(),
    testPixel: string(),
    theme: number(),
    answers: array(answersSchema),
    article: object({
      id: number(),
      title: string(),
      url: string(),
      cover: object({
        height: number(),
        id: number(),
        url: string(),
        width: string(),
        sizes: sizesSchema,
      }),
      default_cover: sizesSchema,
    }),
  }),
});

export const setQuickPollAnswerIdSchema = object({
  success: boolean(),
  answers: array(object({
    count: number(),
    event: string(),
  })),
  question: object({
    count: number(),
    event: string(),
    question_id: number(),
  }),
});

export const getQuickPollStatisticsSchema = object({
  success: boolean(),
  data: object({
    clicks: record(string(), number()),
    id: number(),
    views: number(),
  }),
});

export const setQuickPollViewedSchema = object({
  success: boolean(),
  data: array(object({
    count: number(),
    event: string(),
    quiz_id: number(),
  })),
});

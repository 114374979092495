// @ts-ignore
import { object, unknown, string, number } from 'valibot';

export const GetArticleViewsCountSchema = object({
  status: string('Error on status'),
  // Приходит id в объекте с полем viewsCount, нет возможности динамического ключа>
  viewsCount: unknown(),
  // number: string('Error on viewsCount number'),
});

export const SyncArticleViewsCountSchema = object({
  msg: string('Error on msg'),
  viewsCount: number('Error on viewsCount'),
});

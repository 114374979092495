// @ts-ignore
import { any, array, boolean, nullish, number, object, string, optional } from 'valibot';

export const reactionsItem = object({
  post_id: number(),
  post_slug: string(),
  post_type: optional(string()),
  user_state: nullish(string()),
  updated_at: string(),
  created_at: string(),
  post_date: nullish(string()),

  thumbs_up_count: optional(number()),
  thumbs_up: optional(array(number())),
  thumbs_down_count: optional(number()),
  thumbs_down: optional(array(number())),
  on_fire_count: optional(number()),
  on_fire: optional(array(number())),
  angry_count: optional(number()),
  angry: optional(array(number())),
  heart_count: optional(number()),
  heart: optional(array(number())),

  // recipes
  like: optional(array(number())),
  like_count: optional(number()),
  dislike: optional(array(number())),
  dislike_count: optional(number()),
});

export const fetchReactionsSchema = object({
  data: array(reactionsItem),
  meta: array(any()),
  msg: string(),
  success: optional(boolean()),
});

export const updateReactionsSchema = object({
  data: array(reactionsItem),
  meta: object({ last_modified: number() }),
  msg: string(),
  success: boolean(),
});

export const updateLikeSchema = object({
  data: array(reactionsItem),
  meta: object({ last_modified: number() }),
  msg: string(),
  success: boolean(),
});

export const updateDislikeSchema = object({
  data: array(reactionsItem),
  meta: object({ last_modified: number() }),
  msg: string(),
  success: boolean(),
});

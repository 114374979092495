// @ts-ignore
import { object, boolean, nullable, string, number } from 'valibot';

export const PushSubscriptionChangeSchema = object({
  success: boolean(),
});

export const GetDeviceInfoSchema = object({
  success: boolean('Error of get device info success'),
  data: object({
    id: string('Error of get id'),
    token: string('Error of get token'),
    type: string('Error of get type'),
    created_at: string('Error of get created_at'),
    updated_at: string('Error of get updated_at'),
    device_id: nullable(string('Error of get device_id')),
    user_agent: string('Error of get user_agent'),
    p256dh: string('Error of get p256dh'),
    auth: string('Error of get auth'),
    subscribed: number('Error of get subscribed'),
    not_found: number('Error of get not_found'),
    test: number('Error of get test'),
    not_found_at: nullable(string('Error of get not_found_at')),
    changed_sub_status_at: nullable(string('Error of get changed_sub_status_at')),
    integer_id: nullable(string('Error of get integer_id')),
  }),
});

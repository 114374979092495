// @ts-ignore
import { string, number, boolean, array, object, nullable, nullish, union } from 'valibot';

const DeleteAtSchema = object({
  date: string('Error on deleted_at date'),
  timezone_type: number('Error on deleted_at timezone_type'),
  timezone: string('Error on deleted_at timezone'),
});

const AvatarSchema = object({
  id: number('Error on id'),
  created_at: string('Error on created_at'),
  updated_at: nullish(string('Error on updated_at')),
  deleted_at: nullish(DeleteAtSchema),
  author_id: number('Error on author_id'),
  url: string('Error on url'),
  author: string('Error on author'),
});

const AuditStatusSchema = object({
  id: number('Error on id'),
  name: string('Error on name'),
  name_ru: string('Error on name_ru'),
  created_at: nullish(string('Error on created_at')),
  updated_at: nullish(string('Error on updated_at')),
});

const ReactionSchema = object({
  id: number('Error on id'),
  author_id: number('Error on author_id'),
  author_name: string('Error on author_name'),
  author_avatar_url: string('Error on author_avatar_url'),
  state: string('Error on state'),
  created_at: string('Error on created_at'),
  updated_at: string('Error on updated_at'),
});

const FileSchema = object({
  comment_id: number('Error on file comment_id'),
  created_at: string('Error on file created_at'),
  updated_at: nullish(string('Error on file updated_at')),
  deleted_at: nullish(DeleteAtSchema),
  path: string('Error on file url'),
  last_modified: number('Error on file last_modified'),
});

const CommentSchema = object({
  id: number('Error on id'),
  post_ID: number('Error on post_ID'),
  audit_status_id: number('Error on audit_status_id'),
  author_id: number('Error on author_id'),
  author: string('Error on author'),
  author_email: nullable(string('Error on author_email')),
  author_url: string('Error on author_url'),
  author_IP: nullable(string('Error on author_IP')),
  date: string('Error on date'),
  date_gmt: string('Error on date_gmt'),
  content: string('Error on content'),
  karma: number('Error on karma'),
  approved: string('Error on approved'),
  agent: string('Error on agent'),
  type: string('Error on type'),
  app: string('Error on app'),
  parent: number('Error on parent'),
  level: number('Error on level'),
  recursive_likes_cnt: number('Error on recursive_likes_cnt'),
  users_liked_cnt: number('Error on users_liked_cnt'),
  users_liked: array(number('Error on users_liked')),
  recursive_dislikes_cnt: number('Error on recursive_dislikes_cnt'),
  users_disliked_cnt: number('Error on users_disliked_cnt'),
  users_disliked: array(number('Error on users_disliked')),
  subscribe: string('Error on subscribe'),
  updated_at: nullish(string('Error on updated_at')),
  shadowed_at: nullable(string('Error on shadowed_at')),
  shadowed_by: nullable(string('Error on shadowed_by')),
  deleted_at: nullish(DeleteAtSchema),
  deleted_data: nullable(string('Error on deleted_data')),
  deleted: boolean('Error on deleted'),
  flags: number('Error on flags'),
  reactions: array(ReactionSchema),
  files: array(FileSchema),
  videos: array(FileSchema),
  avatar: union([AvatarSchema, array({})]),
  audit_status: AuditStatusSchema,
});

const CommentsMeta = object({
  total: number('Error on total'),
  per_page: number('Error on per_page'),
  current_page: number('Error on current_page'),
  last_page: number('Error on last_page'),
  from: number('Error on from'),
  to: number('Error on to'),
  last_modified: number('Error on last_modified'),
});

export const FetchAmpCommentsSchema = object({
  success: boolean('Error on success'),
  data: object({
    ...CommentsMeta.entries,
    data: array(CommentSchema),
  }),
  meta: CommentsMeta,
});

export const FetchLastCommentsSchema = object({
  success: boolean('Error on success'),
  data: array(
    object({
      author: string('Error on author'),
      author_id: number('Error on author_id'),
      content: string('Error on content'),
      date_gmt: string('Error on date_gmt'),
      id: number('Error on id'),
      karma: number('Error on karma'),
      parent: number('Error on parent'),
      post_ID: number('Error on post_ID'),
      updated_at: nullable(string('Error on updated_at')),
      post: object({
        id: number('Error on id'),
        post_ID: number('Error on post_ID'),
        title: string('Error on title'),
        slug: string('Error on slug'),
        type: string('Error on type'),
        published_at_gmt: string('Error on published_at_gmt'),
        created_at: string('Error on created_at'),
        updated_at: string('Error on updated_at'),
      }),
      avatar: AvatarSchema,
      flags: number('Error on flags'),
      files: array(string('Error on files')),
      videos: array(string('Error on videos')),
      app: string('Error on app'),
      audit_status_id: number('Error on audit_status_id'),
      audit_status_name: string('Error on audit_status_name'),
      single_level: number('Error on single_level'),
      users_liked: array(string('Error on users_liked')),
      users_disliked: array(string('Error on users_disliked')),
      users_liked_cnt: number('Error on users_liked_cnt'),
      users_disliked_cnt: number('Error on users_disliked_cnt'),
      post_title: string('Error on post_title'),
    }),
  ),
});

export const FetchCommentsCountSchema = object({
  success: boolean('Error on success'),
  data: array(
    object({
      post_ID: number('Error on post_ID'),
      count: number('Error on count'),
    }),
  ),
});

// @ts-ignore
import { number, array, unknown, object, optional, string } from 'valibot';

const VisibilityConditionSchema = object({
  type: string(),
  id: optional(number()),
  value: optional(string()),
});

const VisiblitySchema = object({
  show: array(optional(VisibilityConditionSchema)),
  hide: array(optional(VisibilityConditionSchema)),
});

export const WidgetsSchema = object({
  id: string('error on id'),
  type: string(' error on type'),
  title: optional(string('error on title')),
  items: optional(array(unknown())),
  data: optional(array(unknown())),
  visibility: VisiblitySchema,
});

export const fetchWidgetsSchema = object({
  'twenty-sidebar': optional(array(WidgetsSchema)),
  'twenty-single-left': optional(array(WidgetsSchema)),
  'twenty-footer': optional(array(WidgetsSchema)),
  'twenty-above-header': optional(array(WidgetsSchema)),
  'twenty-below-header': optional(array(WidgetsSchema)),
  'twenty-between-posts': optional(array(WidgetsSchema)),
  'twenty-scripts': optional(array(WidgetsSchema)),
  'twenty-above-comments': optional(array(WidgetsSchema)),
  'twenty-below-comments': optional(array(WidgetsSchema)),
  'twenty-after-excerpt': optional(array(WidgetsSchema)),
  'twenty-post-dynamic': optional(array(WidgetsSchema)),
  'twenty-amp-after-first-paragraph': optional(array(WidgetsSchema)),
  'twenty-inside-post': optional(array(WidgetsSchema)),
  'twentry-after-third-paragrapth': optional(array(WidgetsSchema)),

  'health-sidebar': optional(array(WidgetsSchema)),
  'health-main-page': optional(array(WidgetsSchema)),
  'health-above-menu': optional(array(WidgetsSchema)),
  'health-below-menu': optional(array(WidgetsSchema)),
  'health-after-the-first-paragraph': optional(array(WidgetsSchema)),
  'health-after-thitd-paragraph': optional(array(WidgetsSchema)),
  'health-after-the-eighth-paragraph': optional(array(WidgetsSchema)),
  'health-post-dynamic': optional(array(WidgetsSchema)),
  'health-above-comments': optional(array(WidgetsSchema)),
  'health-below-comments': optional(array(WidgetsSchema)),
  'health-footer': optional(array(WidgetsSchema)),
  'health-scripts': optional(array(WidgetsSchema)),
});

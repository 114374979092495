// @ts-ignore
import { number, object, optional, string, array, any, boolean } from 'valibot';
import { ImgSchema } from '../generalSchemas';

export const recipeWidgetsSchema = object({
  type: string('Error on type'),
  id: string('Error on id'),
  title: optional(string('Error on title')),
  moreLink: optional(string('Error on moreLink')),
  fixed: optional(boolean('Error on fixed')),
  visibility: object({
    show: optional(array(any())),
    hide: optional(array(any())),
  }),
  data: optional(
    array(
      object({
        id: number('Error on id'),
        type: string('Error on type'),
        attributes: any(),
        text: string('Error on text'),
        children: array(any()),
      }),
    ),
  ),
  items: optional(any()),
  list: optional(string('Error on list')),
  recipes: optional(array(
    object({
      postId: string('Error on postId'),
      title: string('Error on title'),
      link: string('Error on link'),
      images: array(optional(ImgSchema)),
    }),
  ),
  ),
});

export const fetchRecipesWidgetsSchema = object({
  'recipes-above-header': optional(array(recipeWidgetsSchema)),
  'recipes-below-header': optional(array(recipeWidgetsSchema)),
  'recipes-list-sidebar': optional(array(recipeWidgetsSchema)),
  'recipe-sidebar': optional(array(recipeWidgetsSchema)),
  'recipes-above-comments': optional(array(recipeWidgetsSchema)),
  'recipes-below-comments': optional(array(recipeWidgetsSchema)),
  'recipes-below-ingredients': optional(array(recipeWidgetsSchema)),
  'recipes-between-posts': optional(array(recipeWidgetsSchema)),
});

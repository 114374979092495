// @ts-ignore
import { object, string, url } from 'valibot';

export const MascotResponseSchema = object({
  items: object({
    burger: object({
      link: url('Error on burger link'),
      buttonText: string('Error on burger buttonText'),
      messageText: string('Error on burger messageText'),
    }),

    footer: object({
      link: url('Error on footer link'),
      buttonText: string('Error on footer buttonText'),
      messageText: string('Error on footer messageText'),
    }),
  }),
});

import {
  number,
  object,
  unknown,
  string,
  array,
  literal,
  boolean,
  union,
  nullish,
  optional,
  // @ts-ignore
} from 'valibot';
import { IronMaidenSinglePostSchema, IronMaidenMetaSchema } from './ironMaiden';
import { FetchAmpCommentsSchema } from './talker';
import { WidgetsSchema } from './wordpress/widgetsSchema';
import { TagSchema, TContentSchema, AuthorOrExpertSchema, BreadcrumbSchema } from './health/health';
import { SingleRecipeSchema } from './wordpress/fetchRecipesSchema';
import { SeoIronMaidenSchema, ImgSchema, ImageSchema } from './generalSchemas';
import { recipeWidgetsSchema } from './wordpress/recipeWidgetsSchema';

const GatewayResponseSchema = object({
  headers: unknown(),
  reason: string('Error on reason'),
  status: number('Error on status'),
});

export const GetArticleDataSchema = object({
  article: object({
    ...GatewayResponseSchema.entries,
    body: IronMaidenSinglePostSchema,
  }),
  comments: object({
    ...GatewayResponseSchema.entries,
    body: FetchAmpCommentsSchema,
  }),
  widgets: object({
    ...GatewayResponseSchema.entries,
    body: object({
      'twenty-above-comments': array(WidgetsSchema),
      'twenty-below-comments': array(WidgetsSchema),
      'twenty-after-excerpt': array(WidgetsSchema),
      'twenty-post-dynamic': array(WidgetsSchema),
      'twenty-footer': array(WidgetsSchema),
      'twenty-single-left': array(WidgetsSchema),
    }),
  }),
});

const TransformedHealthArticle = object({
  id: number('error on id'),
  title: string('error on title'),
  type: literal('health'),
  name: string('error on name'),
  url: string('error on url'),
  teaserUrl: string('error on teaserUrl'),
  date: number('error on date'),
  excerpt: string('error on excerpt'),
  author: nullish(AuthorOrExpertSchema),
  expert: nullish(AuthorOrExpertSchema),

  // TODO: what
  img: array(ImgSchema, 'Error on img'),
  image: ImageSchema,
  defaultImg: array(ImgSchema, 'Error on defaultImg'),
  categories: unknown(),
  tags: array(TagSchema),
  isAdvertising: boolean('Error on isAdvertising'),
  isPromo: boolean('Error on isPromo'),
  caption: string('Error on caption'),
  commentsEnabled: boolean('Error on commentsEnabled'),
  comments_count: number('Error on commentsCount'),
  abTitlesData: object({
    titles: string('Error on abTitlesData titles'),
    clicks: string('Error on abTitlesData clicks'),
    views: string('Error on abTitlesData views'),
  }),
  views: number('Error on views'),
  adsToken: string('Error on adsToken'),
  canHaveReactions: boolean('Error on reactionsEnabled'),
  testPixel: array(string('Error on testPixel')),
  customAuthors: optional(string('Error on custom_authors')),
  advertLabel: string('Error on advertLabel'),
  template: union([
    literal('one-column'),
    literal('two-column'),
    literal('three-column'),
    literal('card'),
    literal('promo'),
    literal('broadside'),
    literal('toc'),
  ]),
  jsonLd: string('Error on jsonLd'),
  ampEnabled: boolean('Error on amp_enabled'),
  lastModified: string('Error on lastModified'),
  structuredContent: array(TContentSchema),
  show_authors_experts: boolean('Error on show_authors_experts'),
  meta: object({ ...IronMaidenMetaSchema.entries, breadcrumb: optional(array(BreadcrumbSchema)) }),
});

export const GetHealthArticleDataSchema = object({
  article: object({
    ...GatewayResponseSchema.entries,
    body: object({ item: TransformedHealthArticle, seo: SeoIronMaidenSchema }),
  }),
  comments: object({
    ...GatewayResponseSchema.entries,
    body: FetchAmpCommentsSchema,
  }),
  widgets: object({
    ...GatewayResponseSchema.entries,
    body: object({
      'health-above-comments': array(WidgetsSchema),
      'health-below-comments': array(WidgetsSchema),
      'health-footer': array(WidgetsSchema),
      'health-post-dynamic': array(WidgetsSchema),
      'health-after-the-first-paragraph': array(WidgetsSchema),
    }),
  }),
});

export const GetRecipeDataSchema = object({
  recipe: object({
    ...GatewayResponseSchema.entries,
    body: SingleRecipeSchema,
  }),
  comments: object({
    ...GatewayResponseSchema.entries,
    body: FetchAmpCommentsSchema,
  }),
  widgets: object({
    ...GatewayResponseSchema.entries,
    body: object({
      'recipe-sidebar': optional(array(recipeWidgetsSchema)),
      'recipes-above-comments': optional(array(recipeWidgetsSchema)),
      'recipes-below-comments': optional(array(recipeWidgetsSchema)),
      'recipes-below-ingredients': optional(array(recipeWidgetsSchema)),
    }),
  }),
});

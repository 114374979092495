import {
  number,
  object,
  url,
  string,
  picklist,
  array,
  lazy,
  optional,
  boolean,
  union,
  nullish,
  unknown,
  // @ts-ignore
} from 'valibot';
import { AuthorExpertSchema, ImageSchema, ImgSchema } from './generalSchemas';

const SourceSchema = object({
  url: nullish(string('Error on meta source url')),
  text: nullish(string('Error on meta source name')),
});

const AdfoxParamsSchema = object({
  lh_adfox_terms: string('Error in adfoxParams lh_adfox_terms'),
  lh_adfox_tags: string('Error in adfoxParams lh_adfox_tags'),
  lh_adfox_streams: string('Error in adfoxParams lh_adfox_streams'),
  lh_adfox_noad: string('Error in adfoxParams lh_adfox_noad'),
  lh_adfox_noadsense: number('Error in adfoxParams lh_adfox_noadsense'),
  lh_adfox_postid: number('Error in adfoxParams lh_adfox_postid'),
  lh_adfox_pk: string('Error in adfoxParams lh_adfox_pk'),
  lh_adfox_homepage: boolean('Error in adfoxParams lh_adfox_homepage'),
});

const ContentSchema:any = object({
  id: number('Error in content id'),
  type: string('Error in content type'),
  attributes: object(unknown(), 'Error in content attributes'),
  text: string('Error in content text'),
  children: lazy(() => array(ContentSchema, 'Error in content children')),
  parser_type: optional(
    picklist(['html_parser', 'gutenberg_parser'], 'Error in content parser_type'),
  ),
});

const CategorySchema = object({
  id: number('Error in category id'),
  title: string('Error in category title'),
  slug: string('Error in category slug'),
  url: string('Error in category url'),
  color: nullish(string('Error in category color')),
  icon: nullish(string('Error in category icon')),
  parsed_description: nullish(array(ContentSchema, 'Error in category parsed_description')),
  og_image: nullish(string('Error in category og_image')),
  type: string('Error in category type'),
  image: nullish(string('Error in category image')),
});

const BaseIronMaidenPostSchema = object({
  id: number('Error on id'),
  title: string('Error on title'),
  type: picklist(['post', 'page', 'health'], 'Error on type'),
  name: string('Error on name'),
  url: url('Error on url'),
  date: number('Error on date'),
  teaserUrl: string('Error on teaserUrl'),
  excerpt: string('Error on excerpt'),
  author: AuthorExpertSchema,
  expert: union([AuthorExpertSchema, object({})]),
  categories: array(CategorySchema, 'Error on categories'),
  tags: array(
    object({
      id: number('Error on tag id'),
      type: string('Error on tag type'),
      title: string('Error on tag title'),
      slug: string('Error on tag slug'),
      url: string('Error on tag url'),
    }),
    'Error on tags',
  ),
  isAdvertising: boolean('Error on isAdvertising'),
  isPromo: boolean('Error on isPromo'),
  img: array(ImgSchema, 'Error on img'),
  image: ImageSchema,
  caption: string('Error on caption'),
  defaultImg: array(ImgSchema, 'Error on defaultImg'),
  commentsEnabled: boolean('Error on commentsEnabled'),
  comments_count: number('Error on comments_count'),
  abTitlesData: object({}, 'Error on abTitlesData'),
  views: number('Error on views'),
  adsToken: nullish(string('Error on adsToken')),
  canHaveReactions: boolean('Error on canHaveReactions'),
  testPixel: array(string('Error on testPixel')),
  custom_authors: string('Error on custom_authors'),
  advertLabel: string('Error on advertLabel'),
  template: picklist(
    ['one-column', 'two-column', 'promo', 'broadside', 'toc'],
    'Error on template',
  ),
  jsonLd: string('Error on jsonLd'),
  ampEnabled: boolean('Error on ampEnabled'),
  show_authors_experts: boolean('Error on show_authors_experts'),
  lastModified: string('Error on lastModified'),
});

const SeoSchema = object({
  title: string('Error on title'),
  description: string('Error on description'),
  robots: string('Error on robots'),
  canonical: url('Error on canonical'),
  og_image: nullish(url('Error on og_image')),
  site_icon: url('Error on site_icon'),
  schema_graph: string('Error on schema_graph'),
  schema_howto: string('Error on schema_howto'),
});

export const IronMaidenListSchema = object({
  isUserLoggedIn: boolean('Error on isUserLoggedIn'),
  totalPages: number('Error on totalPages'),
  totalItems: number('Error on totalItems'),
  currentPage: number('Error on currentPage'),
  limit: number('Error on limit'),
  offset: number('Error on offset'),
  title: nullish(string('Error on meta title')),

  seo: SeoSchema,
  items: array(BaseIronMaidenPostSchema),
});

export const IronMaidenMetaSchema = object({
  mainColor: nullish(string('Error on meta mainColor')),
  promo: object({
    bgColor: nullish(string('Error on meta promo bgColor')),
    url: nullish(url('Error on meta promo url')),
    images: nullish(
      object({
        topDesktop: nullish(string('Error on meta images topDesktop')),
        topMobile: nullish(string('Error on meta images topMobile')),
        desktop: nullish(string('Error on meta images desktop')),
        mobile: nullish(string('Error on meta images mobile')),
      }),
    ),
  }),
  customAuthors: string('Error on meta custom_authors'),
  source: SourceSchema,
  cover_source: SourceSchema,
  adsSource: SourceSchema,
  lh_first_publish_date: string('Error on meta lh_first_published_time'),
});

export const IronMaidenSinglePostSchema = object({
  isUserLoggedIn: boolean('Error on isUserLoggedIn'),
  adfoxParams: AdfoxParamsSchema,
  seo: SeoSchema,

  item: object({
    ...BaseIronMaidenPostSchema.entries,
    structuredContent: array(ContentSchema, 'Error on structuredContent'),
    meta: IronMaidenMetaSchema,
  }),
});

// @ts-ignore
import { number, object, url, string, nullable, nullish, array, union } from 'valibot';

export const ImgSchema = object({
  width: number('Error on img width'),
  height: number('Error on img height'),
  url: string('Error on img url'),
});

export const ImageSchema = object({
  imageId: number('Error in image id'),
  origin: union([ImgSchema, string('Error in image origin')]),
  alt: string('Error in image alt'),
  sizes: array(ImgSchema),
});

export const AuthorExpertSchema = object({
  name: string('Error in author expert name'),
  avatar: string('Error in author expert avatar'),
  url: string('Error in author expert url'),
  profile: string('Error in author expert profile'),
  about: nullish(string('Error in author expert about')),
  integer_id: nullable(number('Error in author expert integer_id')),
  expertise: nullable(string('Error in author expert expertise')),
  telegram: nullish(string('Error in author expert telegram')),
  vkontakte: nullish(string('Error in author expert vkontakte')),
  twitter: nullish(string('Error in author expert twitter')),
});

export const SeoIronMaidenSchema = object({
  title: string('Error on title'),
  description: string('Error on description'),
  robots: string('Error on robots'),
  canonical: url('Error on canonical'),
  og_image: nullish(url('Error on og_image')),
  site_icon: url('Error on site_icon'),
  schema_graph: nullish(string('Error on schema_graph')),
  schema_howto: nullish(string('Error on schema_howto')),

  // TODO узнать используем ли мы schema где-то, если нет - попросить бэк выпилить/убрать из valibot'а
  schema: nullish(string('Error on schema')),
});
